// analytics.ts: handles Google Analytics

type GTagFunction = (...args: any[]) => void;
declare const gtag: GTagFunction;
let globalCompanyId: string | null = null;


export const initGoogleAnalytics = (measurementId: string) => {
    // Load gtag.js script
    console.log("Initializing Google Analytics with measurement ID: " + measurementId);
    const script = document.createElement("script");
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${measurementId}`;
    document.head.appendChild(script);

    // Initialize Google Analytics
    window.dataLayer = window.dataLayer || [];

    function gtagFunction() {
        window.dataLayer.push(arguments);
    }

    window.gtag = gtagFunction;

    gtag("js", new Date());
    gtag("config", measurementId);
};

export const setGACompanyId = (companyId: string) => {
    if (window.gtag) {
        window.gtag("set", "company_id", companyId);
    }
    console.log("setting GA company_id parameter: " + companyId);
    globalCompanyId = companyId;
};


export const logEvent = (action: string, params: any = {}) => {
    if (window.gtag) {
        if (globalCompanyId) {
            params["company_id"] = globalCompanyId;
        }
        window.gtag("event", action, params);
    }
};