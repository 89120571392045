import {useDispatch} from "react-redux";
import {fetchForm} from "./store/api";
import {setAnswer, setError, setForm, setMaxReward, setTeaserVisible} from "./store/questionFlowSlice";
import React from "react";

export const useFormFetch = (company_id: string) => {
    const dispatch = useDispatch();

    React.useEffect(() => {
        fetchForm(company_id).then(
            (form) => {
                dispatch(setForm(form));
                console.log("Furst: initialization of furst, should be the only api call needed");
                let maxReward = 0;
                form.questions.forEach((question) => {
                    maxReward += question.reward;
                    dispatch(setAnswer({question: question.id, answer: ""}));
                });
                dispatch(setMaxReward(maxReward));
                dispatch(setTeaserVisible(Math.random() * 100 < form.rolloutPercentage));
                console.log("Furst: decided whether to show");
            },
            (error) => dispatch(setError(error.message))
        );
    }, [dispatch, company_id]);
};
