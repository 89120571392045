// FailurePage: when any failure occurs, this page shows in the modal.

import {Box, Button, Stack, Typography} from "@mui/material";
import React from "react";

interface FailurePageProps {
    message: string;
    onClose: () => void;
}

function FailurePage({message, onClose}: FailurePageProps) {
    return (
        <Stack spacing={2} margin={4} sx={{justifyContent: "center", textAlign: "center"}}>
            <Stack
                spacing={4}
                marginTop={8}
                marginBottom={8}
            >
                <Box display="flex" justifyContent="center"> 
                    <Box width="10rem">
                        <img src={`${process.env.PUBLIC_URL}/sad_face.svg`} alt="Sad face" width="100%" />
                    </Box>
                 </Box>
                <Stack spacing={2}>
                    <Typography variant="h5">
                        Uh oh...
                    </Typography>
                    <Typography variant="subtitle2">
                        {message}
                    </Typography>
                </Stack>
            </Stack>
            <Stack
                direction="row"
                spacing={2}
                justifyContent="space-between"
                alignItems="center"
            >
                <Button
                    component="a"
                    href="mailto:help@furstparty.com"
                    target="_blank"
                    rel="noopener noreferrer"
                    color="primary"
                    variant="text"
                    sx={{
                        textDecoration: "none",
                    }}
                >
                    Learn more
                </Button>
                <Button
                    type="submit"
                    variant="contained"
                    onClick={onClose}
                    color="primary"
                >
                    Done
                </Button>
            </Stack>
        </Stack>
    );
}

export default FailurePage;
