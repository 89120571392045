// index.tsx: pretty sure this is the file that runs first. It reads the script tag on the page, gets the necessary info, and calls renderMyWidget.

import {configureStore} from "@reduxjs/toolkit";
import questionFlowReducer from "./store/questionFlowSlice";
import {initGoogleAnalytics, setGACompanyId} from "./analytics";
import {renderMyWidget} from "./widget";

// set measurement ID of Google Analytics. 
initGoogleAnalytics(process.env.ga_id || "G-VBJGC4YQR8");

configureStore({
    reducer: {
        questionFlow: questionFlowReducer,
    },
});

function getWidgetAttributesFromScriptTag() {
    const scriptTag = document.getElementById("furst-widget") as HTMLScriptElement;

    if (!scriptTag) {
        console.error("Script tag not found.");
        return null;
    }
    const company_id = scriptTag.getAttribute("company_id");
    console.log("Parsed company ID: " + company_id);
    if (!company_id) {
        return null;
    }
    return company_id;
}

// Get the company_id, and render the widget
const widgetAttributes = getWidgetAttributesFromScriptTag();
if (widgetAttributes) {
    const company_id = widgetAttributes;
    setGACompanyId(company_id); // Set the company_id as a custom dimension in Google Analytics
    renderMyWidget(company_id);
} else {
    console.error("Company ID is missing from the script tag.");
}
