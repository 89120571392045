// questionFlowSlice.ts: a slice of a Redux store used for state management.

import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Answer, Form, QuestionFlowState} from "./types";

const initialState: QuestionFlowState = {
    form: {
        id: 0,
        name: "",
        description: "",
        teaserPrompt: "",
        teaserLocation: "",
        questionPagePrompt: "",
        emailPagePrompt: "",
        successPagePromptOne: "",
        successPagePromptTwo: "",
        rolloutPercentage: 0,
        accentColor: "#00ff00",
        font: "Inter",
        failureNoBudgetPrompt: "",
        failureEmailUsedPrompt: "",
        failureGenericPrompt: "",
        questions: [],
    },
    email: "",
    answers: {},
    step: 1,
    error: "",
    modalOpen: false,
    teaserVisible: false,
    maxReward: 0,
    claimedReward: 0,
};

const questionFlowSlice = createSlice({
    name: "questionFlow",
    initialState,
    reducers: {
        setForm: (state, action: PayloadAction<Form>) => {
            state.form = action.payload;
        },
        setEmail: (state, action: PayloadAction<string>) => {
            state.email = action.payload;
        },
        setAnswer: (state, action: PayloadAction<Answer>) => {
            const {question, answer} = action.payload;
            state.answers[question] = answer;
        },
        setStep: (state, action: PayloadAction<number>) => {
            state.step = action.payload;
        },
        setError: (state, action: PayloadAction<string>) => {
            state.error = action.payload;
        },
        setModalOpen: (state, action: PayloadAction<boolean>) => {
            state.modalOpen = action.payload;
        },
        setTeaserVisible: (state, action: PayloadAction<boolean>) => {
            state.teaserVisible = action.payload;
        },
        setMaxReward: (state, action: PayloadAction<number>) => {
            state.maxReward = action.payload;
        },
        setClaimedReward: (state, action: PayloadAction<number>) => {
            state.claimedReward = action.payload;
        },
        resetQuestionFlow(state) {
            state.email = "";
            state.step = 1;
            state.error = "";
        }
    },
});

export const {
    setForm,
    setEmail,
    setAnswer,
    setStep,
    setError,
    setModalOpen,
    setTeaserVisible,
    setMaxReward,
    setClaimedReward,
    resetQuestionFlow,
} = questionFlowSlice.actions;

export default questionFlowSlice.reducer;
