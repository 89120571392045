// api.ts: handles all the API calls to the backend

import {Answer, AnswerMapping, EmailValidationError, Form, Question} from "./types";

//TODO fix
const API_ENDPOINT_VALIDATE_EMAIL =
    "https://dry-beyond-56624.herokuapp.com/users/record_email";
const API_ENDPOINT_SUBMIT_RESPONSE =
    "https://dry-beyond-56624.herokuapp.com/users/submit_response";
const API_ENDPOINT_FORM = "https://dry-beyond-56624.herokuapp.com/users/get_form";


export async function fetchForm(company_id: string): Promise<Form> {
    const response = await fetch(`${API_ENDPOINT_FORM}/${encodeURIComponent(company_id)}`, {
        headers: getHeaders(),
    });
    if (!response.ok) {
        const message = await response.text();
        throw new Error(message);
    }
    const formData = await response.json();
    return {
        id: formData.id,
        name: formData.name,
        description: formData.description,
        teaserPrompt: formData.teaser_prompt,
        questionPagePrompt: formData.question_page_prompt,
        emailPagePrompt: formData.email_page_prompt,
        successPagePromptOne: formData.success_page_prompt_one,
        successPagePromptTwo: formData.success_page_prompt_two,
        failureNoBudgetPrompt: formData.failure_out_of_budget_prompt,
        failureEmailUsedPrompt: formData.failure_email_used_prompt,
        failureGenericPrompt: formData.failure_generic_prompt,
        teaserLocation: formData.teaser_location,
        accentColor: formData.accent_color,
        font: formData.font,
        rolloutPercentage: parseFloat(formData.rollout_percentage),
        questions: formData.questions
            .map((questionData: any): Question => ({
                id: questionData.id,
                prompt: questionData.prompt,
                hint: questionData.hint,
                reward: parseFloat(questionData.reward),
                required: questionData.required,
            })),
    };
}


function getHeaders(): { accept: string } {
    return {
        accept: "application/json"
    };
}

export async function shouldProceed(company_id: string, email: string) {
    const response = await fetch(
        `${API_ENDPOINT_VALIDATE_EMAIL}/${encodeURIComponent(company_id)}/${encodeURIComponent(email)}/`,
        {
            method: "POST",
            headers: getHeaders(),
        }
    );
    if (response.ok) {
        return true;
    } else {
        const data = await response.json();
        throw new EmailValidationError(data.message);
    }
}


export async function submitResponse(
    company_id: string,
    email: string,
    answer_mapping: AnswerMapping,
    total_reward: number
): Promise<boolean> {
    const convertedAnswers: Answer[] = [];
    for (const [question_id, answer_value] of Object.entries(answer_mapping)) {
        if (answer_value.trim() === "") {
            continue;
        }
        convertedAnswers.push({
            question: question_id,
            answer: answer_value.trim(),
        });
    }
    if (convertedAnswers.length === 0) {
        throw new Error("No valid answers");
    }
    const data = {
        email: email,
        answers: convertedAnswers,
        reward: total_reward,
    };
    const body_content = JSON.stringify(data);
    const response = await fetch(`${API_ENDPOINT_SUBMIT_RESPONSE}/${encodeURIComponent(company_id)}`, {
        method: "POST",
        headers: {
            ...getHeaders(),
            "Content-Type": "application/json",
        },
        body: body_content,
    });
    if (!response.ok) {
        const responseData = await response.json();
        throw new Error(responseData.message);
    }
    return response.ok;
}

export function calculateClaimedReward(answers: AnswerMapping, form: Form) {
    let claimedReward = 0;

    for (const [questionId, answerValue] of Object.entries(answers)) {
        const question = form.questions.find(
            (q) => q.id === questionId
        );
        if (question && answerValue !== "") {
            claimedReward += question.reward;
        }
    }
    return claimedReward;
}