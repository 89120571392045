// QuestionFlow.tsx: this is the main logic of the "flow of questions". It first renders a "teaser" card, which opens the form modal when clicked. It also handles the hiding and showing of each step of the form. 

import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useTheme} from "@mui/material/styles";
import {
    setAnswer,
    setClaimedReward,
    setEmail,
    setError,
    setModalOpen,
    setStep,
    setTeaserVisible
} from "./store/questionFlowSlice";
import {calculateClaimedReward, shouldProceed, submitResponse} from "./store/api";
import EmailPage from "./components/EmailPage";
import QuestionPage from "./components/QuestionPage";
import {Card, Dialog, IconButton, Typography} from "@mui/material";
import CloseIcon from "@mui/icons-material/CloseSharp";
import SuccessPage from "./components/SuccessPage";
import FailurePage from "./components/FailurePage";
import {Stack} from "@mui/system";
import {logEvent} from "./analytics";
import {EmailValidationError} from "./store/types";

interface QuestionFlowProps {
    company_id: string;
    container: HTMLDivElement;
}

function QuestionFlow({company_id, container}: QuestionFlowProps) {
    const dispatch = useDispatch();
    const theme = useTheme();
    const [
        email,
        step,
        error,
        modalOpen,
        answers,
        teaserVisible,
        form,
    ] = useSelector((state: any) => [
        state.questionFlow.email,
        state.questionFlow.step,
        state.questionFlow.error,
        state.questionFlow.modalOpen,
        state.questionFlow.answers,
        state.questionFlow.teaserVisible,
        state.questionFlow.form
    ]);

    useEffect(() => {
        if (form.teaserLocation) {
            setWidgetPosition(container, form.teaserLocation);
        }
    }, [container, form.teaserLocation]);

    function setWidgetPosition(container: HTMLDivElement, location: string) {
        console.log("Widget location set:" + location);
        switch (location) {
            case "bottom_right":
                container.hidden = false;
                container.style.bottom = "0";
                container.style.right = "0";
                container.style.left = "";
                container.style.top = "";
                break;
            case "bottom_left":
                container.hidden = false;
                container.style.bottom = "0";
                container.style.left = "0";
                container.style.top = "";
                container.style.right = "";
                break;
            case "top_right":
                container.hidden = false;
                container.style.top = "0";
                container.style.right = "0";
                container.style.bottom = "";
                container.style.left = "";
                break;
            case "top_left":
                container.hidden = false;
                container.style.top = "0";
                container.style.left = "0";
                container.style.bottom = "";
                container.style.right = "";
                break;
            default:
                container.hidden = true;
                break;
        }
    }

    const handleEmailSubmit = (email: string) => {
        logEvent("submittedEmail", {event_category: "submit"});
        shouldProceed(company_id, email)
            .then((isValid: boolean) => {
                if (isValid) {
                    dispatch(setEmail(email));
                    dispatch(setStep(step + 1));
                } else {
                    dispatch(setError(form.failureGenericPrompt));
                }
            })
            .catch((error: Error) => {
                if (error instanceof EmailValidationError) {
                    dispatch(setError(error.message));
                } else {
                    dispatch(setError(form.failureGenericPrompt));
                }
            });
    };
    const handleAnswerChange = (id: string, value: string) => {
        dispatch(setAnswer({question: id, answer: value}));
    };

    const handleAnswerSubmit = async () => {
        const updatedClaimedReward = calculateClaimedReward(answers, form);
        dispatch(setClaimedReward(updatedClaimedReward));
        logEvent("submittedAnswers", {event_category: "submit"});
        return submitResponse(company_id, email, answers, updatedClaimedReward)
            .then(() => {
                dispatch(setStep(step + 1));
            })
            .catch((error: Error) => {
                dispatch(setError(error.message));
            });
    };

    const handleCloseModal = () => {
        dispatch(setModalOpen(false));
        logEvent("closedModal", {event_category: "click"});
    };

    const handleStart = () => {
        dispatch(setModalOpen(true));
        logEvent("clickedTeaser", {event_category: "click"});
    };

    const handleTeaserClose = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation(); // prevent event from bubbling up to the card element
        dispatch(setTeaserVisible(false));
        logEvent("closedTeaser", {event_category: "click"});
    };

    const hideEverything = () => {
        dispatch(setTeaserVisible(false));
        dispatch(setModalOpen(false));
    };

    if (!teaserVisible) {
        return null;
    }
    console.log(step);


    return (
        <>
            {/*this is the 'teaser' card. Only shows when the modal isn't open*/}
            {!modalOpen && teaserVisible && step !== 3 && (
                <Card
                    color="primary"
                    onClick={handleStart}
                    style={{
                        position: "relative",
                        // default position: bottom right
                        bottom: 0,
                        right: 0,
                        marginTop: "1rem",
                        marginBottom: "1rem",
                        marginLeft: "1rem",
                        marginRight: "1rem",
                        cursor: "pointer",
                        paddingLeft: "1rem",
                        paddingRight: "1rem",
                        paddingTop: "0.5rem",
                        paddingBottom: "0.5rem",
                        zIndex: 1299,
                    }}
                >
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <Typography sx={{fontSize: '1rem'}}>{form.teaserPrompt}</Typography>
                        <IconButton onClick={handleTeaserClose}>
                            <CloseIcon sx={{fontSize: "x-large"}}/>
                        </IconButton>
                    </Stack>
                </Card>
            )}

            <Dialog
                open={modalOpen}
                onClose={handleCloseModal}
                fullWidth={true}
                maxWidth={"sm"}
            >
                <IconButton
                    sx={{
                        position: "absolute", top: "1.8rem", right: "1.8rem", zIndex: 1300,
                        [theme.breakpoints.down("sm")]: {
                            top: "1rem", // smaller margin for mobile
                            right: "1rem", // smaller margin for mobile
                        },
                    }}
                    onClick={handleCloseModal}
                >
                    <CloseIcon sx={{fontSize: "x-large"}}/>
                </IconButton>
                {error && <FailurePage message={error} onClose={hideEverything}/>}
                {!error && step === 1 && <EmailPage onSubmit={handleEmailSubmit}/>}
                {!error && step === 2 && (
                    <QuestionPage
                        onAnswerChange={handleAnswerChange}
                        onSubmit={handleAnswerSubmit}
                    />
                )}
                {!error && step === 3 && <SuccessPage onClose={hideEverything}/>}
            </Dialog>
        </>
    );
}

export default QuestionFlow;