import {Provider} from "react-redux";
import store from "./store/store";
import QuestionFlow from "./QuestionFlow";
import {ThemeProvider} from "@emotion/react";
import {setTheme} from "./styles/theme";
import {useAccentColor, useFont} from "./styles/loadBrandStyles";
import React, {useMemo} from "react";
import {useFormFetch} from "./useFormFetch";

interface ThemeWrapperProps {
    children: React.ReactNode;
    company_id: string;
}

const ThemeWrapper: React.FC<ThemeWrapperProps> = ({children, company_id}) => {
    useFormFetch(company_id);

    const accentColor = useAccentColor();
    const font = useFont();
    const theme = useMemo(() => {
        return setTheme(accentColor, font);
    }, [accentColor, font]);

    return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};


interface AppProps {
    company_id: string;
    container: HTMLDivElement;
}

const App: React.FC<AppProps> = ({company_id, container}) => {
    return (
        <Provider store={store}>
            <ThemeWrapper company_id={company_id}>
                <div>
                    <QuestionFlow company_id={company_id} container={container}/>
                </div>
            </ThemeWrapper>
        </Provider>
    );
};

export default App;