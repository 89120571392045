// theme.ts: set styles globally for the frontend

import {createTheme} from "@mui/material";
import {pink} from "@mui/material/colors";

export const setTheme = (accentColor: string, font: string) => {
    const myTheme = createTheme({
        palette: {
            mode: "light",
            primary: {main: accentColor},
            secondary: pink,
            text: {
                primary: "#373F47", // applies to text on the background. will be a setting
                secondary: "#ffffff", // applies to text in buttons, chips, etc. will be a setting
                disabled: "#B4BBC3",
            },
        },

        typography: {
            fontFamily: [font, "Arial"].join(","),
            subtitle1: {
                fontWeight: 600,
                fontSize: "1.2rem",
            },

            subtitle2: {
                fontWeight: 400,
                fontSize: "1rem",
            },

            caption: {
                fontWeight: 400,
                fontSize: "1rem",
                fontStyle: "italic",
                color: "#808080",
            },

            h4: {
                fontWeight: 600,
            },
        },

        components: {},
    });

    myTheme.typography = {
        ...myTheme.typography,
        subtitle1: {
            fontFamily: myTheme.typography.fontFamily,
            color: myTheme.palette.text.primary,
            fontWeight: 600,
            fontSize: "1.2rem",
        },
    };

    myTheme.components = {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: "none",
                    fontSize: "1rem",
                    fontWeight: 600,
                    // color: myTheme.palette.text.secondary,
                },
            },
        },

        MuiPaper: {
            styleOverrides: {
                root: {
                    borderRadius: "12px",
                    padding: "1rem",
                    backgroundColor: "#fff", // will be a setting
                    [myTheme.breakpoints.down("sm")]: {
                        padding: "0.5rem", // smaller padding for mobile
                    },
                },
            },
        },

        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#B4BBC3",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#B4BBC3",
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#4A4A4A",
                    },
                },
            },

        }
    };

    return myTheme;
};
