// Load brand styles from Redux

import {useSelector} from "react-redux";

export function useAccentColor(): string {
    return useSelector((state: any) => state.questionFlow.form.accentColor);
}

export function useFont(): string {
    return useSelector((state: any) => state.questionFlow.form.font);
}
