// Question: a UI component that renders each question in the form. It handles the fake "validation" animation to trick users into thinking there's some real validaiton so they don't bs all the answers. 

import React, {useState} from "react";
import {Field} from "formik";
import {Box, Chip, CircularProgress, TextField, Typography,} from "@mui/material";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {useDispatch, useSelector} from "react-redux";
import {calculateClaimedReward} from "../store/api";
import {setClaimedReward} from "../store/questionFlowSlice";

interface QuestionProps {
    question: any;
    onAnswerChange: (id: string, value: string) => void;
}

function Question({
                      question,
                      onAnswerChange,
                  }: QuestionProps) {
    // local states
    const [isValid, setIsValid] = useState(false);
    const [validationInProgress, setValidationInProgress] = useState(false);

    //redux state
    const [answers, form] = useSelector((state: any) => [state.questionFlow.answers, state.questionFlow.form]);
    const dispatch = useDispatch();

    return (
        <Box>
            <Typography
                variant="subtitle2"
                marginLeft={"0.1rem"}
                marginBottom={"0.2rem"}
            >
                {question.prompt}
                {question.required ? " *" : ""}
            </Typography>

            <Field
                as={TextField}
                name={question.id.toString()}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    onAnswerChange(question.id, e.target.value);
                }}
                onBlur={() => {
                    setValidationInProgress(true);
                    setTimeout(() => {
                        setIsValid(answers[question.id]);
                        setValidationInProgress(false);
                        const updatedClaimedReward = calculateClaimedReward(answers, form);
                        dispatch(setClaimedReward(updatedClaimedReward));
                    }, 420);
                }}
                variant="outlined"
                placeholder={question.hint}
                sx={{width: "100%"}}
                value={answers[question.id]} // use value from Redux state
                required={question.required}
                InputProps={{
                    endAdornment: (
                        <React.Fragment>
                            {question.reward !== 0 && validationInProgress ? (
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        marginRight: "1rem",
                                    }}
                                >
                                    <CircularProgress size={20}/>
                                </Box>
                            ) : (
                                <Box></Box>
                            )}
                            {question.reward !== 0 && (
                                <Chip
                                    label={`$${question.reward.toFixed(2)}`}
                                    variant="filled"
                                    size="medium"
                                    color={isValid ? "primary" : "default"}
                                    sx={{
                                        color: isValid ? "text.secondary" : "default",
                                    }}
                                    icon={
                                        isValid ? <CheckCircleIcon/> : <RadioButtonUncheckedIcon/>
                                    }
                                />)}
                        </React.Fragment>
                    ),
                }}
            />
        </Box>
    );
}

export default Question;
