import React from "react";
import { useSelector } from "react-redux";

import { Box, Button, Stack, Typography } from "@mui/material";

interface SuccessPageProps {
  onClose: () => void;
}

function SuccessPage({ onClose }: SuccessPageProps) {
  const [claimedReward, form] = useSelector((state: any) => [
    state.questionFlow.claimedReward,
    state.questionFlow.form,
  ]);

  return (
    <Stack
      spacing={2}
      margin={4}
      sx={{ justifyContent: "center", textAlign: "center" }}
    >
      <Stack spacing={4} marginTop={8} marginBottom={8}>
        <Box display="flex" justifyContent="center"> 
          <Box width="5rem">
            <img src={`${process.env.PUBLIC_URL}/mail.svg`} alt="Mail" width="100%" />
          </Box>
        </Box>
        <Stack spacing={2}>
          <Typography variant="h5">{form.successPagePromptOne}</Typography>
          {claimedReward !== 0 && (
            <Typography variant="h4">{`$${claimedReward.toFixed(2)}`}</Typography>
          )}
          <Typography variant="subtitle2">
            {form.successPagePromptTwo}
          </Typography>
        </Stack>
      </Stack>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
      >
        <Button
          component="a"
          href="mailto:help@furstparty.com"
          target="_blank"
          rel="noopener noreferrer"
          color="primary"
          variant="text"
          sx={{
            textDecoration: "none",
          }}
        >
          How do I redeem?
        </Button>
        <Button
          type="submit"
          variant="contained"
          onClick={onClose}
          color="primary"
        >
          Done
        </Button>
      </Stack>
    </Stack>
  );
}

export default SuccessPage;