// EmailPage: the first page of the modal which collects the visitor's email. 

import React, {useEffect, useRef} from "react";
import {Field, Form, Formik} from "formik";
import {Button, DialogContent, Stack, TextField, Typography} from "@mui/material";
import {Box} from "@mui/system";
import {useSelector} from "react-redux";


interface EmailPageProps {
    onSubmit: (email: string) => void;
}

function EmailPage({onSubmit}: EmailPageProps) {
    const emailRef = useRef<HTMLInputElement>(null);

    // redux state
    const maxReward = useSelector((state: any) => state.questionFlow.maxReward);
    const brandForm = useSelector((state: any) => state.questionFlow.form);


    useEffect(() => {
        if (emailRef && emailRef.current) {
            emailRef.current.focus();
        }
    }, []);

    return (
        <Formik
            initialValues={{email: ""}}
            onSubmit={(values) => {
                onSubmit(values.email);
            }}
        >
            <Form>
                <DialogContent>
                    <Typography variant="h5" marginBottom={4}>
                        {brandForm.emailPagePrompt}
                    </Typography>

                    <Stack spacing={2}>
                        <Box width="100%">
                            <Typography
                                variant="subtitle2"
                                marginLeft={"0.1rem"}
                                marginBottom={"0.2rem"}
                            >
                                Email
                            </Typography>
                            <Field
                                as={TextField}
                                fullWidth
                                name="email"
                                type="email"
                                variant="outlined"
                                required
                                inputRef={emailRef}
                            />
                            <Typography
                                variant="caption"
                                marginLeft={"0.1rem"}
                                marginBottom={"0.2rem"}
                            >
                                Your reward will be sent to this email address
                            </Typography>
                        </Box>
                        <Box sx={{width: "100%", textAlign: "right"}}>
                            {maxReward !== 0 &&
                                <Typography variant="subtitle1">
                                    Reward: up to ${maxReward.toFixed(2)}
                                </Typography>}
                        </Box>
                        <Box sx={{display: "flex", justifyContent: "right"}}>
                            <Button type="submit" variant="contained" color="primary">
                                Continue
                            </Button>
                        </Box>
                    </Stack>
                </DialogContent>
            </Form>
        </Formik>
    );
}

export default EmailPage;
