export interface Question {
    id: string;
    prompt: string;
    hint: string;
    reward: number;
    required: boolean;
}

export interface Form {
    id: number;
    name: string;
    description: string;
    questionPagePrompt: string;
    emailPagePrompt: string;
    successPagePromptOne: string;
    successPagePromptTwo: string;
    teaserPrompt: string;
    rolloutPercentage: number;
    accentColor: string;
    font: string;
    teaserLocation: string;
    failureNoBudgetPrompt: string;
    failureEmailUsedPrompt: string;
    failureGenericPrompt: string;
    questions: Question[];
}

export interface Answer {
    question: string;
    answer: string;
}

export type AnswerMapping = Record<string, string>;

export interface QuestionFlowState {
    form: Form;
    email: string;
    answers: AnswerMapping;
    step: number;
    error: string;
    modalOpen: boolean;
    teaserVisible: boolean;
    maxReward: number;
    claimedReward: number;
}

export class EmailValidationError extends Error {
    constructor(message: string) {
        super(message);
        this.name = "EmailValidationError";
        Object.setPrototypeOf(this, EmailValidationError.prototype);
    }
}
