import {configureStore} from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import questionFlowReducer from "./questionFlowSlice";

const store = configureStore({
    reducer: {
        questionFlow: questionFlowReducer,
    },
    middleware: [thunk]
});

export default store;
