// widget.tsx: renders the widget on top of any webpage. 

import ReactDOM from "react-dom";
import App from "./App";
import {logEvent} from "./analytics";


export function renderMyWidget(company_id: string) {
    console.log("Rendering widget for company ID: " + company_id);
    logEvent("renderedWidget", {event_category: "view"});
    const container = document.createElement("div");
    container.hidden = true; // start off hidden until we know a location. This eliminates flickering when the async location loads.
    container.style.position = "fixed";
    container.style.zIndex = "9999";
    container.id = "FurstContainer";
    document.body.appendChild(container);

    const appWrapper = document.createElement("div");
    container.appendChild(appWrapper);

    // Pass the company_id as a prop to the App component
    ReactDOM.render(<App company_id={company_id} container={container}/>, appWrapper);
}

// Export the function to make it available globally
(window as any).renderMyWidget = renderMyWidget;
