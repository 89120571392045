//QuesitonPage: the 2nd step in the modal where a list of quetions are displayed. 

import React, {useState} from "react";
import {Form, Formik} from "formik";
import {Box, Button, DialogContent, Typography} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import {Stack} from "@mui/system";
import {useSelector} from "react-redux";
import Question from "./Question";

interface QuestionPageProps {
    onSubmit: () => void;
    onAnswerChange: (question_id: string, value: string) => void;
}

function QuestionPage({onSubmit, onAnswerChange}: QuestionPageProps) {
    //redux state
    const [maxReward, claimedReward, form, answers] = useSelector(
        (state: any) => [
            state.questionFlow.maxReward,
            state.questionFlow.claimedReward,
            state.questionFlow.form,
            state.questionFlow.answers,
        ]
    );

    // Add a loading state to the 'submit' button to prevent it from submitting before reward calculation can finish updating.
    const [loading, setLoading] = useState(false);

    return (
        <Formik
            initialValues={answers}
            onSubmit={async () => {
                setLoading(true);
                await onSubmit();
                setLoading(false);
            }}
            enableReinitialize={true}
        >
            {() => {
                return (
                    <Form>
                        <DialogContent>
                            <Typography variant="h5" marginBottom={4}>
                                {form.questionPagePrompt}
                            </Typography>
                            <Stack spacing={2}>
                                {form.questions.map((question: any) => (
                                    <Box key={question.id} width="100%">
                                        <Question
                                            question={question}
                                            onAnswerChange={onAnswerChange}
                                        />
                                    </Box>
                                ))}

                                <Box sx={{width: "100%", textAlign: "right"}}>
                                    {maxReward !== 0 &&
                                        <Typography variant="subtitle1">
                                            $ {claimedReward.toFixed(2)} / {maxReward.toFixed(2)}
                                        </Typography>}
                                </Box>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={loading}
                                >
                                    {loading ? <CircularProgress size={24}/> : "Submit"}
                                </Button>
                            </Stack>
                        </DialogContent>
                    </Form>
                );
            }}
        </Formik>
    );
}

export default QuestionPage;
